<template>
    <div class="contents">
        <h1 >{{ $t('titles.contents_overview') }}</h1>
        <LoadedContent  :loaded="isLoaded" :error="isError">
            <BaseTable :columns="columns" :data="data" />
            <PageFooter>
                <BasePagination :getUrl="getPageUrl" :loadData="loadData" />
                <AddItemButton @click="openModal('content_type')" />
            </PageFooter>
        </LoadedContent>
        <teleport to="#app">
            <ContentTypesSelection :content="{}"  :opened="openedModal === 'content_type'" @close="closeModal()" @updateValues="createContent" />
            <PagesSelection :content="activeItem"  :opened="openedModal === 'pages_selection'" @close="closeModal()" />
        </teleport>

    </div>
</template>

<script>
import BaseTable from "@common/components/crud/BaseTable";
import { useStore } from 'vuex';
import { useI18n } from 'vue-i18n';
import { computed, defineAsyncComponent, provide, ref,  } from 'vue';
import LoadedContent from '@common/components/base/LoadedContent';
import { useRouter } from 'vue-router';
import BasePagination from '@common/components/crud/BasePagination';
import AddItemButton from '@admin/components/base/AddItemButton';
import PageFooter from '@admin/components/base/PageFooter';

export default {
    name: "Contents",
    components: {
        BaseTable,
        LoadedContent,
        BasePagination,
        AddItemButton,
        PageFooter,
        ContentTypesSelection: defineAsyncComponent(() => import(/* webpackChunkName: "content-type-selection" */'@admin/components/cms/content/ContentTypeSelection')),
        PagesSelection: defineAsyncComponent(() => import(/* webpackChunkName: "pages-selection" */'@admin/components/cms/content/PagesSelection')),
    },
    setup(){
        provide("crud_store","content")

        const store = useStore();
 
        const i18n = useI18n();

        const router = useRouter();

        
        // loading data
        store.dispatch('content_type/fetchItems',{});

        const loadData = () => {
            if(router.currentRoute.value.name !== 'contents' && router.currentRoute.value.name !== 'contents_overview'){
                return;
            }
            let page = 1;
            if(router.currentRoute.value.params.page_num){
                page = router.currentRoute.value.params.page_num;
            } 
            store.dispatch('content/fetchItems',{page: page});
        };  
        loadData();
        // watchEffect(() => loadData());

        const columns = [
            {
                type: "link",
                icon: () => "pen",
                content: () => '',
                link: (record) => 'url.project/:project_code/url.contents/!'+record.code,
            },
            { 
                type: "info",
                header: i18n.t("controls.name"),
                filter: true,
                key: "name",
                title: i18n.t("controls.name"),
                class: "w-xl",
            },
            // { 
            //     type: "info",
            //     header: i18n.t("controls.description"),
            //     filter: true,
            //     key: "description",
            //     title:i18n.t("controls.description"),
            //     class: "w-xxl",
            // },
            {
                type: 'button',
                // header: i18n.t("cms.pages"),
                // header_title: i18n.t("cms.pages_with_content"),
                content: () => '',
                icon: () => 'blocks',
                title: i18n.t("cms.pages_with_content"),
                action: (record) => openModal('pages_selection',record)
            }

        ];

          // getting data
        const data = computed(() => store.getters['content/getItems']);

        const isLoaded = computed(() => store.getters["content/isLoaded"]);
        
        const isError = computed(() => store.getters["content/isError"]);

        // modal window
        const activeItem = ref(null);
        const openedModal = ref(null);
        const openModal = (modal,record = null) => {
            openedModal.value = modal;
            activeItem.value = record;
        }
        const closeModal = () => {
            openedModal.value = null;
            activeItem.value = null;
        }

        // pagination
        const getPageUrl = (number) => {
            return 'url.project/:project_code/url.contents/url.overview/!'+number;
        }

        const createContent = ({typeCode}) => {
            let data = {
                typeCode,
                name: i18n.t('cms.content_block')
            }
            store.dispatch('content/createItem',{data});
        }


        return {
            columns,
            data,
            isLoaded,
            isError,
            closeModal,
            activeItem,
            openModal,
            getPageUrl,
            openedModal,
            createContent,
            loadData
        }

    }
}
</script>

<style scoped>
.contents,.loaded-content{
    display: flex;
    flex-direction: column;
    height: 100%;
}
::v-deep(.base-table){
    margin: 1rem;
    border-collapse: separate;
    border-spacing: 0 0.2rem;
    min-width: initial;
}
::v-deep(.base-table-row:nth-child(odd) td) {
    background: #fff6e6;
}

::v-deep(.base-table-row:nth-child(even) td) {
    background: #ffe7bb;
}
::v-deep(.base-table-row td) {
    padding: 0.5rem 0.2rem;
}
::v-deep(.base-table-row td.no-padding) {
    padding: 0;
}
::v-deep(.base-table td:first-child) { 
    border-top-left-radius: 1rem; 
    border-bottom-left-radius: 1rem;
    padding: 0.5rem 0.2rem 0.5rem 0.5rem;
}
::v-deep(.base-table td:last-child) { 
    border-top-right-radius: 1rem; 
    border-bottom-right-radius: 1rem;
    padding: 0.5rem 0.5rem 0.5rem 0.2rem;
}
::v-deep(.loader .base-loader) {
    left: 2rem;
    top:0;
}
</style>