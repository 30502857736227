<template>
    <div class="page-footer">
        <slot></slot>
    </div>
</template>

<script>
export default {
    name: "PageFooter"
}
</script>

<style scoped>
.page-footer{
    background: white;
    position: sticky;
    bottom: 0;
    box-shadow: inset 0 1px 0 #dcd6cb;
    display: flex;
    justify-content: space-between;
}

</style>