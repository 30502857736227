<template>
    <span @click="loadData(num)" >
        <RouterLinkLocal v-if="url !== null" class="link btn" :link="url" >
            {{ text }}
        </RouterLinkLocal>
        <span v-else class="link btn" >{{ text }}</span>
    </span>
</template>

<script>
import { inject } from 'vue'
export default {
    name: "BasePaginationLink",
    props: {
        num: Number,
        url: String,
        text: String,
    },
    setup(){
        const loadData = inject('loadData');

        return {
            loadData
        }
    }
}
</script>

<style scoped>
.link{
    margin: 0.2rem;
    border-radius: 1rem;
    padding: 0;
    width: 1.5rem;
    height: 1.5rem;
    line-height: 1.5rem;
    color: var(--dark-color);
}
.active>.link{
    background-color: rgb(134 193 241);
    pointer-events: none;
    cursor: default;
}


</style>