<template>
    <div class="base-pagination">
        <BasePaginationLink v-if="firstPage" :url="firstPage.url" :num="firstPage.num" :text="'&#8249;&#8249;'" />
        <BasePaginationLink v-if="beforePage" :url="beforePage.url" :num="beforePage.num" :text="'&#8249;'" />
        <span class="spacer"></span>
        <BasePaginationLink :class="{active: link.num == currentPageNum}" v-for="(link,index) in links" :key="index" v-bind="link" />
        <span class="spacer"></span>
        <BasePaginationLink v-if="nextPage" :url="nextPage.url" :num="nextPage.num" :text="'&#8250;'" />
        <BasePaginationLink v-if="lastPage" :url="lastPage.url" :num="lastPage.num" :text="'&#8250;&#8250;'" />
    </div>
</template>

<script>
import useRecordModel from "@common/composable/BaseTableData";
import { computed, provide } from 'vue';
import BasePaginationLink from './BasePaginationLink';

export default {
    name: "BasePagination",
    components: {
        BasePaginationLink
    },
    props: {
        getUrl: {
            type: Function,
        },
        loadData: {
            type: Function
        }
    },
    setup(props) {
        provide('loadData',props.loadData);
        
        const { 
            pageCount,
            currentPageNum
        } = useRecordModel();

        const createLink = (number) => {
            return {
                url: props.getUrl(number.toString()),
                text:  number.toString(),
                num:  number,
            }
        };

        const links = computed(() => {
            let array = [];
            let num = parseInt(currentPageNum.value);
            let count = parseInt(pageCount.value);
            if(count == 1){
                return array;
            }
            if(num - 2 > 0){
                array.push(createLink(num - 2));
            }
            if(num - 1 > 0){
                array.push(createLink(num - 1));
            }
            array.push(createLink(num));
            if(num + 1 <= count){
                array.push(createLink(num + 1));
            }
            if(num + 2 <= count){
                array.push(createLink(num + 2));
            }
            return array;
        });
        const firstPage = computed(() => {
            let num = parseInt(currentPageNum.value);
            if(num - 1 < 1){
                return false;
            }
            return createLink(1);
        });
        const beforePage = computed(() => {
            let num = parseInt(currentPageNum.value);
            if(num - 1 < 1){
                return false;
            }
            return createLink(num - 1);
        });
        const nextPage = computed(() => {
            let num = parseInt(currentPageNum.value);
            let count = parseInt(pageCount.value);
            if(num + 1 > count){
                return false;
            }
            return createLink(num + 1);
        });

        const lastPage = computed(() => {
            let num = parseInt(currentPageNum.value);
            let count = parseInt(pageCount.value);
            if(num + 1 > count){
                return false;
            }
            return createLink(count);
        });
                
        return {
            pageCount,
            currentPageNum,
            links,
            firstPage,
            lastPage,
            beforePage,
            nextPage
        }

    }
}
</script>

<style scoped>
.base-pagination{
    margin: 0.5rem 1rem;
}
.spacer{
    width: 1rem;
    display: inline-block;
}

</style>>
