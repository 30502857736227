<template>
    <BaseButton type="button" class="btn-success add-item-button" :title="$t('controls.add_new_item')">
        <BaseIcon :class="icon" />
    </BaseButton>
</template>

<script>
export default {
    name: "AddItemButton",
    props: {
        icon: {
            type: String,
            default: () => 'plus_black'
        }
    }
}
</script>

<style scoped>
.add-item-button{
    width: 2.5rem;
    height: 2.5rem;
    padding: 0.5rem;
    border-radius: 50%;
    margin: 0.3rem 1rem 0.3rem 0;
}

</style>